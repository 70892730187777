<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
          v-model="dialogReplaceCourse"
          scrollable
          persistent
          max-width="1000px"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">
                Thay thế/lưu trữ khóa học:
                <span v-if="Object.keys(course).length > 0" class="font-weight-bold">
                  (#{{ course.id }} - {{ course.name.vi }})
                </span>
              </span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogReplaceCourse = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <div class="d-flex" style="width: 100%">
                    <label class="text-h6 font-weight-bold" style="width: 20%">
                      Khóa học thay thế:
                    </label>
                    <v-autocomplete
                        dense outlined
                        v-model="replacement_course_id"
                        :items="list_reducer_course"
                        item-text="name.vi"
                        item-value="id"
                        placeholder="Chọn khóa học ..."
                        clearable
                    ></v-autocomplete>
                  </div>
                  <p class="red--text mb-0">(Nếu không chọn “Khoá học thay thế” hệ thống hiểu rằng bạn đang lưu trữ khoá học)</p>
                </v-col>
                <v-col cols="12" class="d-flex">
                  <label class="text-h6 font-weight-bold" style="width: 20%">
                    Ngày bắt đầu <span class="red--text">*</span>
                  </label>
                  <date-picker
                      class="ml-2"
                      v-model="replacement_date_input"
                      value-type="format"
                      format="DD-MM-YYYY"
                      :disabled-date="disabledFromPastDateAndToDayForDatePicker"
                  />
                </v-col>
<!--                <v-col cols="12" class="d-flex">-->
<!--                  <label class="text-h6 font-weight-bold" style="width: 20%">-->
<!--                    Nội dung thông báo <span class="red&#45;&#45;text">*</span>-->
<!--                  </label>-->
<!--                  <v-text-field-->
<!--                      outlined dense-->
<!--                      placeholder="Nhập nội dung thông báo ..."-->
<!--                  ></v-text-field>-->
<!--                </v-col>-->
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="red darken-1"
                text
                @click="dialogReplaceCourse = false"
            >Close
            </v-btn>
            <v-btn outlined color="blue darken-1" text @click="btnReplaceCourse()">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";
import ApiService from "@/service/api.service";
export default {
  name: "CourseReplaceForm",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    course: {
      type: Object,
      default: () => {},
    },
    list_reducer_course: {
      type: Array,
      default: () => [],
    },

  },
  data() {
    return {
      is_call_api: false,
      replacement_course_id: null,
      replacement_date_input: null,
    }
  },
  computed: {
    dialogReplaceCourse: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    show_dialog(val) {
      if (val) {
        this.setDataForm()
      }
    },
  },
  methods: {
    setDataForm() {
      console.log(this.course)
      let archived_record = this.course.archived_record;
      if (archived_record) {
        this.replacement_course_id = archived_record.replacement_course_id;
        this.replacement_date_input = archived_record.replacement_date;
      } else {
        this.replacement_course_id = null;
        this.replacement_date_input = null;
      }
    },
    async btnReplaceCourse() {
      let vm = this;
      if (!this.validateForm()) {
        return;
      }
      let data = {
        course_id: this.course.id,
        replacement_date: this.replacement_date_input,
      }
      if (!this.$utils.isValueInvalid(this.replacement_course_id)) {
        data.replacement_course_id = this.replacement_course_id;
      }
      vm.is_call_api = true;
      await ApiService.post("prep-app/courses/"+this.course.id+"/replacement", data)
        .then(function (res) {
          if (res.status === 200 || res.status === 201) {
            vm.is_call_api = false;
            vm.$emit("resetList");
            Swal.fire({
              title: "",
              text: res.data,
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
            vm.dialogReplaceCourse = false;
          }
        })
        .catch(function (error) {
          vm.is_call_api = false;
          if (error.response.status === 422) {
            vm.errorMess(error.response.data.error.message);
          }
        });
    },
    validateForm() {
      let flat = true;
      if (this.$utils.isValueInvalid(this.replacement_date_input)) {
        this.errorMess("Bạn chưa nhập thông tin ngày bắt đầu”");
        flat = false;
      }
      return flat;
    },
    errorMess(msg = '') {
      this.$toasted.error(msg, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    disabledFromPastDateAndToDayForDatePicker(date) {
      const today = new Date();
      today.setHours(23, 59, 59, 59);
      return date < today;
    },
  }
}
</script>

<style scoped>

</style>